
    export const LISTITEMS: any[] = [
        {"url":"/home", "name":"Entrenamientos", "icon":'ft-calendar'},
        {"url":"/team/list", "name":"Equipos", "icon":'ft-shield'},
        {"url":"/player/list", "name":"Deportistas", "icon":'ft-users'}
    ]

    export const LISTITEMS_EN: any[] = [
        {"url":"/home", "name":"Training Calendar", "icon":'ft-calendar'},
        {"url":"/team/list", "name":"Teams", "icon":'ft-shield'},
        {"url":"/player/list", "name":"Athletes", "icon":'ft-users'}
    ]

    export const LISTITEMS_PT: any[] = [
        {"url":"/home", "name":"Entrenamientos", "icon":'ft-calendar'},
        {"url":"/team/list", "name":"Equipos", "icon":'ft-shield'},
        {"url":"/player/list", "name":"Deportistas", "icon":'ft-users'}
    ]

    export const LISTITEMS_DE: any[] = [
        {"url":"/home", "name":"Entrenamientos", "icon":'ft-calendar'},
        {"url":"/team/list", "name":"Equipos", "icon":'ft-shield'},
        {"url":"/player/list", "name":"Deportistas", "icon":'ft-users'}
    ]
