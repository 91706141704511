import { Injectable } from "@angular/core";
import { NgbDateStruct, NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private translate: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.translate.instant(`DATEPICKER.WEEKDAYS.${weekday}`);
  }

  getWeekdayLabel(weekday: number, width?: 'short' | 'narrow' | 'long'): string {
    return this.translate.instant(`DATEPICKER.WEEKDAYS.${weekday}`);
  }

  getMonthShortName(month: number): string {
    return this.translate.instant(`DATEPICKER.MONTHS_SHORT.${month}`);
  }

  getMonthFullName(month: number): string {
    return this.translate.instant(`DATEPICKER.MONTHS_FULL.${month}`);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}