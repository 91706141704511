
export class Trainer {
    id:           string;
    createdAt:    string | null;
    email:        string | null;
    enabled:      boolean;
    idTeam:       string;
    idUser:       string;
    mobilePhone:  string | null;
    name:         string | null;
    imagePath:    string | null;    

    constructor(init?: Partial<Trainer>) {
        this.createdAt = init['created_at'];
        this.id        = init['id'];
        this.email     = init['email'];
        this.enabled   = init['enabled'];
        this.idTeam    = init['id_team'];
        this.idUser    = init['id_user'];
        this.mobilePhone = init['mobile_phone'];
        this.name      = init['name'];
        this.imagePath = init['image_path'];
    }
}


export class TrainerModel {
    id_team:       string;

    constructor(init?: Partial<Trainer>) {
        this.id_team    = init['idTeam'];
    }
}
