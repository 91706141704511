import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map, take } from 'rxjs/operators';
import { Trainer, TrainerModel } from '../models/trainer';

const Entity = 'trainers';

@Injectable({
  providedIn: 'root'
})
export class TrainerService {

  constructor(private db: AngularFireDatabase) { }

  public getOneByUid(uId: string) {    
    return this.db.list<Trainer>('/'+ Entity , ref => ref.orderByChild('id_user').equalTo(uId).limitToFirst(1))
    .snapshotChanges()
    .pipe(            
      map(list =>
        {
          let element = list.find(x=>x!==undefined);
          if(element!==undefined) return new Trainer({ id: element.payload.key, ...element.payload.val() })
          return undefined;
        }),
      take(1)
    ).toPromise();
  }

  put(id: string, trainer: Trainer) {
    return this.db.object<TrainerModel>('/'+ Entity + '/' + id).update(new TrainerModel(trainer));
  }
}