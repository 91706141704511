import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { RoutingConstants } from '../routes/routing-constants';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.currentUserSubject.pipe(
        takeWhile(user => user === null)
      ).subscribe({
        complete: () => {
          if(!this.authService.currentUserValue.trainer)
          {
            this.router.navigate([RoutingConstants.Login]);
            resolve(false);
          }
          resolve(true);
        }
      })
    })  
  }
}
