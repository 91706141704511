<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:" (click)="toggleSearchOpenClass(true)"><i
              class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="Explore ..." tabindex="0"
              autofocus data-search="template-search" [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent >
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name | translate" (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()"  >
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name | translate }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Smartseason logo" [src]="logoUrl"></div><span
                    class="text">Smartseason</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown >
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                [src]="selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')"><img
                  class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" /><span
                  class="font-small-3">English</span></a><a class="dropdown-item" href="javascript:;" data-language="es"
                (click)="ChangeLanguage('es')"><img class="langimg mr-2" src="./assets/img/flags/es.png"
                  alt="flag" /><span class="font-small-3">Spanish</span></a><a class="dropdown-item" href="javascript:;"
                data-language="pt" (click)="ChangeLanguage('pt')"><img class="langimg mr-2"
                  src="./assets/img/flags/pt.png" alt="flag" /><span class="font-small-3">Portuguese</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="de" (click)="ChangeLanguage('de')"><img
                  class="langimg mr-2" src="./assets/img/flags/de.png" alt="flag" /><span
                  class="font-small-3">German</span></a>
            </div>
          </li>
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-center" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle>
              
              <div class="avatar avatar-md d-flex align-items-center justify-content-center"
                *ngIf="currentTeamSubject?.value?.imagePath?.length > 0; else customTeamAvatar">
                <div class="avatar-content ">
                  <img class="rounded-circle" [src]="currentTeamSubject?.value?.imagePath"
                    height="40" width="40" alt="datatable-avatar">
                </div>
              </div>              
              
              <ng-template #customTeamAvatar>
                <div class="avatar avatar-md d-flex align-items-center justify-content-center">
                  <div class="avatar-content ">
                    <i class="avatar-icon ft-shield"></i>
                  </div>
                </div>              
              </ng-template>              
              
              <div class="user d-md-flex d-none ml-1">
                <span class="text-right">{{currentTeamSubject?.value?.name}} {{currentTeamSubject?.value?.category}} ({{currentTeamSubject?.value?.gender}})</span>
              </div>
            </a>
            <ul ngbDropdownMenu class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">
              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-shield font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">{{ "TEAM.LIST.TITLE" | translate }} </span>
                  </div>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <div *ngFor="let team of trainnerTeamsSubject | async; index as i">
                  <a *ngIf="currentTeamSubject?.value?.id !== team.id" class="d-flex justify-content-between" (click)="changeTeam(team)">
                    <div class="media d-flex align-items-center">
                      <div class="media-left">
                        <div class="avatar avatar-lg mr-3" *ngIf="team.imagePath?.length > 0; else customAvatar">
                          <img class="avatar avatar-lg" [src]="team.imagePath" alt="avatar" height="45"
                            width="45" />
                        </div>
                        <ng-template #customAvatar>
                          <div class="avatar avatar-lg mr-3">
                            <div class="avatar-content"><i class="avatar-icon ft-shield"></i></div>
                          </div>
                        </ng-template>
                      </div>
                      <div class="media-body">
                        <h6 class="m-0">
                          <span>{{team.name}} {{team.category}} ({{team.gender}})</span>
                        </h6>                        
                      </div>
                    </div>
                  </a>
                </div>                
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-center" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="avatar avatar-md d-flex align-items-center justify-content-center"
                *ngIf="currentUser.trainer?.imagePath?.length > 0; else customTrainerAvatar">
                <div class="avatar-content ">
                  <img class="rounded-circle" [src]="currentUser.trainer?.imagePath"
                    height="40" width="40" alt="datatable-avatar">
                </div>
              </div>              
              <ng-template #customTrainerAvatar>
                <div class="avatar avatar-md d-flex align-items-center justify-content-center">
                  <div class="avatar-content ">
                    <i class="avatar-icon fa fa-user"></i>
                  </div>
                </div>              
              </ng-template>              
              <div class="user d-md-flex d-none ml-2">
                <span class="text-right">{{this.currentUser?.trainer?.name}}</span>
              </div>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a>
              <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My Inbox</span>
                </div>
              </a> 
              <div class="dropdown-divider"></div>-->
              <a class="dropdown-item" (click)="logOut();">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>





</nav>
