import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map, take } from 'rxjs/operators';
import { Team, TeamModel } from '../models/team';

const Entity = 'teams';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private db: AngularFireDatabase) { }

  public getOneById(id: string) {
    
    return this.db.list<Team>('/'+ Entity , ref => ref.orderByKey().equalTo(id).limitToFirst(1))
    .snapshotChanges()
    .pipe(            
      map(list =>
        {
          let element = list.find(x=>x!==undefined);
          if(element!==undefined) return new Team({ id: element.payload.key, ...element.payload.val() })
          return undefined;
        }),
      take(1)
    ).toPromise();
  }

  getAllByTrainerId(idTrainer: string) {
    return this.db.list<Team>('/'+ Entity , ref => ref.orderByChild('id_trainer').equalTo(idTrainer))
    .snapshotChanges()
    .pipe(            
      map(list =>
        {
          let results = Array<Team>();
          list.forEach(element => {
              if(element!==undefined)
              {
                  results.push(new Team({ id: element.payload.key, ...element.payload.val() }));
              }
          });
          return results;
        }),
      take(1)
    ).toPromise();
  }

  post(id: string, team: Team) {
    if (id == null || id == undefined || id == '') id = this.getNewId();
    return this.db.object<TeamModel>('/'+ Entity + '/' + id).set(new TeamModel(team));
  }

  put(id: string, team: Team) {
    return this.db.object<TeamModel>('/'+ Entity + '/' + id).update(new TeamModel(team));
  }

  getNewId() {
    return this.db.createPushId();
  }
}