export const RoutingConstants = {
    Root: '',
    Home:  'home',
    Login: 'login',
    Error: 'error',
    Training: {
      Add: 'training/add',
      Edit: 'training/edit/:id',
      Dashboard: 'training/schedule/:id/dashboard'
    },
    Team: {
      List: 'team/list',
      Add: 'team/add',
      Edit: 'team/edit/:id'    
    },
    Player: {
      List: 'player/list',
      Add: 'player/add',
      Edit: 'player/edit/:id'    
    }
  }