import { Training } from "./training";

export class Team {
    id:         string;
    createdAt:  string | null;
    category:   string;    
    enabled:    boolean;
    name:       string;
    sport:      string;
    trainings:  Training[];
    idTrainer:  string;
    imagePath:  string;
    gender:     string;

    constructor(init?: Partial<Team>) {
        this.createdAt = init['created_at'] ?? null;
        this.id        = init['id'] ?? null;
        this.category  = init['category'] ?? null;
        this.enabled   = init['enabled'] ?? null;
        this.sport     = init['sport'] ?? null;
        this.name      = init['name'] ?? null;
        this.idTrainer = init['id_trainer'] ?? null;
        this.imagePath = init['image_path'] ?? null;
        this.gender    = init['gender'] ?? null;
    }
}

export class TeamModel {
    id:         string;
    created_at:  string | null;
    category:   string;    
    enabled:    boolean;
    name:       string;
    sport:      string;
    id_trainer:  string;
    image_path:  string;
    gender:      string;

    constructor(init?: Partial<Team>) {
        this.created_at = init['createdAt'] ?? null;
        this.id        = init['id'] ?? null;
        this.category  = init['category'] ?? null;
        this.enabled   = init['enabled'] ?? null;
        this.sport     = init['sport'] ?? null;
        this.name      = init['name'] ?? null;
        this.id_trainer = init['idTrainer'] ?? null;
        this.image_path = init['imagePath'] ?? null;
        this.gender    = init['gender'] ?? null;
    }
}
