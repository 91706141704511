import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from '@angular/router';
import { User } from 'app/domain/models/user';
import { TrainerService } from 'app/domain/services/trainer.service';
import firebase from 'firebase/compat/app';
import { BehaviorSubject } from 'rxjs';
import { RoutingConstants } from '../routes/routing-constants';

@Injectable()
export class AuthService {
  
  public currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject(null);
  
  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  constructor(private _firebaseAuth: AngularFireAuth,
    private trainerService: TrainerService,
    private router: Router) {

      this._firebaseAuth.authState.subscribe(
        async firebaseUser => {          
          if (firebaseUser) {
            let trainer = await this.trainerService.getOneByUid(firebaseUser.uid);
            
            let user = new User(firebaseUser);
            
            if (trainer != null) user.trainer = trainer;
              
            this.currentUserSubject.next(user);

          } else {
            this.logout();
          }
      })
  }

  refreshUser() {
    this._firebaseAuth.currentUser
      .then(async firebaseUser => {          
        if (firebaseUser) {
          let trainer = await this.trainerService.getOneByUid(firebaseUser.uid);
          
          let user = new User(firebaseUser);
          
          if (trainer != null) user.trainer = trainer;
            
          this.currentUserSubject.next(user);

        }
    })
  }
  
  signinUser(email: string, password: string): Promise<User> {
    return this._firebaseAuth.signInWithEmailAndPassword(email, password)
      .then( (credential) => {
        return this.trainerService.getOneByUid(credential.user.uid)
          .then( (trainer) => {
            if (trainer != null) {
              let user = new User(credential.user);
              
              user.trainer = trainer;
              
              this.currentUserSubject.next(user);
              
              return user;
            } else {
              this.currentUserSubject.next(null);
              
              throw new Error("User not allowed to use this app.");
            }
          })
          .catch( (err) => {
            this.currentUserSubject.next(null);
            
            throw err;
          })
      })
  }

  async signInWithPopup() {    
    this._firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .then( (credential) => {
      return this.trainerService.getOneByUid(credential.user.uid)
        .then( (trainer) => {
          if (trainer != null) {
            let user = new User(credential.user);
            
            user.trainer = trainer;
            
            this.currentUserSubject.next(user);
            
            return user;
          } else {
            this.currentUserSubject.next(null);
            
            throw new Error("User not allowed to use this app.");
          }
        })
        .catch( (err) => {
          this.currentUserSubject.next(null);
          
          throw err;
        })
    })
  }

  logout() {
    this._firebaseAuth.signOut();
    this.currentUserSubject.next(null);
    this.router.navigate([RoutingConstants.Root]);
  }

  isAuthenticated() {
    return this.currentUserValue != null;
  }

  sendPasswordResetEmail(email: string) {
    this._firebaseAuth.sendPasswordResetEmail(email)
    .then(function() {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  }
  
}
