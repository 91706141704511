export const environment = {
  privateKey: null,
  storageBucket: 'smartseason-5e641.appspot.com',
  firebase: {
    projectId: 'smartseason-5e641',
    appId: '1:341943398320:web:26d18379ec91f179323075',
    databaseURL: 'https://smartseason-5e641-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'smartseason-5e641.appspot.com',
    apiKey: 'AIzaSyCxSzeJHyC9Zb1eVTcNJEpqMFIwHPgLubQ',
    authDomain: 'smartseason-5e641.firebaseapp.com',
    messagingSenderId: '341943398320',
    measurementId: 'G-H4FSFY7B87',
  },
  production: false
};