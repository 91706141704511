import { Trainer } from "./trainer";

export class User {
    displayName: string | null;
    email: string | null;
    phoneNumber: string | null;
    photoURL: string | null;
    providerId: string;
    uid: string;
    trainer: Trainer;

    constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }
} 
