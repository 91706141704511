import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'player',
    loadChildren: () => import('../../player/player.module').then(m => m.PlayerModule)
  },
  {
    path: 'home',
    loadChildren: () => import('../../home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'training',
    loadChildren: () => import('../../training/training.module').then(m => m.TrainingModule)
  },
  {
    path: 'team',
    loadChildren: () => import('../../team/team.module').then(m => m.TeamModule)
  },  
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  }  
];
